import React from "react";
import styled from "styled-components";
import Wholesale from "../assets/images/logo-wholesale.png";
import Clover from "../assets/images/logo-clover.png";

const MainContainer = styled.div`
  max-width: 1320px;
  margin: auto;
  padding: 15px;
`;

const Header = styled.div`
  text-align: right;
  img {
    max-width: 134px;
  }
`;
const BlueGradient = styled.div`
  width: 500px;
  height: 500px;
  position: absolute;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #00b8e6 0%,
    rgba(0, 184, 230, 0) 100%
  );
  opacity: 0.2;
  left: -214px;
  top: -151px;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
    left: -54px;
    top: -51px;
  }
`;

const GreenGradient = styled.div`
  width: 500px;
  height: 500px;
  position: absolute;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #4fe15d 0%,
    rgba(79, 226, 100, 0) 100%
  );
  opacity: 0.1;
  top: 30%;
  right: -100px;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
    top: 40%;
    right: 0;
  }
`;
const CloverLogo = styled.div`
  min-height: 500px;
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5000;
  img {
    max-width: 600px;
    width: 100%;
  }

  @media (max-width: 768px) {
    min-height: 400px;
    img {
      max-width: 300px;
      width: 100%;
    }
  }
`;

const ComingSoonContent = styled.div`
  text-align: center;
  padding: 15px;
  h2 {
    font-style: normal;
    margin: 35px;
    font-weight: 700;
    font-size: 42px;
    line-height: 45px;
    color: #ffffff;
  }

  .loading:after {
    content: " .";
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: white;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 24px;
    }
  }
`;

const ComingSoon = () => {
  return (
    <>
      <BlueGradient />
      <GreenGradient />
      <MainContainer>
        <Header>
          {/* <a href="https://linktr.ee/wholesalepayments" target="_blank"><img src={Wholesale} alt="" /></a> */}
          <CloverLogo>
            <img src={Clover} alt="" />
          </CloverLogo>
          <ComingSoonContent>
            <h2 class="loading">Coming Soon</h2>
          </ComingSoonContent>
        </Header>
      </MainContainer>
    </>
  );
};

export default ComingSoon;
