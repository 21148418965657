import DefaultLayout from '../layout/DefaultLayout';
import ComingSoon from '../screens/comingsoon'


const routes = [
    {
        path: `/`,
        exact: true,
        layout: DefaultLayout,
        component: ComingSoon,
    }
]


export default routes;